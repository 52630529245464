var messages = {
    required: 'Required',
    email: 'Invalid email'
}


function validate(form, options){
    var setings = {
        errorFunction:null,
        submitFunction:null,
        highlightFunction:null,
        unhighlightFunction:null
    }
    $.extend(setings, options);

    var $form;
    if (form == '$(this)'){
        $form = form;
    } else {
        $form = $(form);
    }

    if ($form.length && $form.attr('novalidate') === undefined) {
        $form.on('submit', function(e) {
            e.preventDefault();
        });

        $form.validate({
            errorClass : 'errorText',
            focusCleanup : true,
            focusInvalid : false,
            invalidHandler: function(event, validator) {
                if(typeof(setings.errorFunction) === 'function'){
                    setings.errorFunction(form);
                }
            },
            errorPlacement: function(error, element) {
                error.appendTo( element.closest('.form_row'));
            },
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error');
                $(element).closest('.form_row').addClass('error').removeClass('valid');
                if( typeof(setings.highlightFunction) === 'function' ) {
                    setings.highlightFunction(form);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).removeClass('error');
                if($(element).closest('.form_row').is('.error')){
                    $(element).closest('.form_row').removeClass('error').addClass('valid');
                }
                if( typeof(setings.unhighlightFunction) === 'function' ) {
                    setings.unhighlightFunction(form);
                }
            },
            submitHandler: function(form) {
                if( typeof(setings.submitFunction) === 'function' ) {
                    setings.submitFunction(form);
                } else {
                    $form[0].submit();
                }
            }
        });

        $.validator.addMethod("regex", function (value, element, regexp) {
            var check = false;
            var re = new RegExp(regexp);
            return this.optional(element) || re.test(value);
        });

        $('[required]',$form).each(function(){
            $(this).rules( "add", {
                required: true,
                messages: {
                    required: messages.required,
                }
            });
        });

        if($('[type="email"]',$form).length) {
            $('[type="email"]',$form).rules( "add", {
                regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/,
                maxlength: 320,
                messages: {
                    regex: messages.email,
                    maxlength: messages.email,
                    email: messages.email,
                    required: messages.required,
                }
            });
        }

    }
}

function validTextNumbers() {
    setInputRegex(".name", /[а-яА-ЯёЁіІїЇєЄ0-9_-]/g);
    setInputRegex(".phone", /[a-zA-Zа-яА-ЯёЁіІїЇєЄ!₴@#$%№₴^&*_/?.`~\=\[\]{};':"\\|,.<>\/?]/g);
    setInputRegex("input:not(.date), textarea", /<script\b[^>]*>([\s\S]*?)<\/script>/gm);
    setInputRegex(".name", /[!₴@#$%№^&*()_+/?.`~\=\[\]{};':"\\|,.<>\/?]*$/);
    setInputRegex(".email", /[{}()\[\];':"\\,<>\/]*$/);

    function setInputRegex(inputType, regex) {
        $(document).on('keyup keypress blur change input', inputType, function () {
            if (this.value.match(regex)) {
                this.value = this.value.replace(regex, "");
            }
            this.value = this.value.replace('  ', ' ');
            this.value = this.value.replace('--', '-');
            this.value = this.value.replace('__', '_');
            this.value = this.value.replace('/', '');
        });
    }
}


function validationCall(form){
    var thisForm = $(form);
    var formSur = thisForm.serialize();

    $.ajax({
        url : thisForm.attr('action'),
        data: formSur,
        method:'POST',
        success: function(data) {
            console.log(data);
            thisForm[0].reset();
        },
    });
}

$(document).ready(function() {
    validTextNumbers();
    validate('#requestForm', {submitFunction:validationCall});
});